@import '../../global.scss';
.Evocom {
    width: 100%;
    height: 90vh;
    z-index: -1;
    @include mobile {
        height: 45vh;
    }
    @include tablet {
        height: 90vh;
    }
  &_container {
    display: flex;
    flex-direction:column ;
    justify-content: center;
    align-items: flex-start;
    height: 100%;
    @include mobile {
        height: 110%;
    }
    @include tablet {
        height: 110%;
    }
  }
   
}
.Evocom_text{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    h1 {
        font-size: 56px;
        font-weight: 700;
        color: #FBFBFB;
        @include mobile {
            font-size: 25px;
            font-weight: 600;
        }
        @include tablet {
            font-size: 36px;
            font-weight: 600;
        }
    }
    p{
        font-size: 22px;
        color: #FBFBFB;
        margin-top: 16px;
        @include mobile {
            font-size: 15px;
            margin-top: 8px;
        }
        @include tablet {
            font-size: 20px;
        }

    }
    .PurpleButton {
        margin-top: 50px;
        font-size: 16px;
        @include mobile {
            margin-top: 27px;
            font-size: 14px;
        }
        @include tablet {
            margin-top: 29px;
            font-size: 14px;
        }
        
            
         
    }
}
