@import '../../global.scss';

.maps_container{
  width: 100%;
  overflow: hidden;
  border: 1px solid #5B3992;
  border-radius: 10px;
  position: relative;
  @include tablet{
    border-radius: 0px;
  }
  @include mobile {
    border-radius: 0px;
  }

  .Maps{
    width: 100%;
    height: 300px;
  }
}