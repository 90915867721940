@import '../../global.scss';
.AboutUs_container {
    background: #FBFBFB;
    border-radius: 20px 20px 0px 0px;
    min-height: 200px;
    padding-top: 105px;
    z-index: 999;
    // padding-right: 166px;
    padding-bottom: 150px;
    @include mobile {
        padding-top: 59px;
        padding-bottom: 80px;
    }
    @include tablet {
        padding-top: 58px;
        padding-bottom: 86px;
    }
}
.AboutUs_headline {
    color: #222121;
    @include mobile {
        font-size: 22px;
    }
    @include tablet {
        font-size: 24px;
    }
}
.AboutUs_text_container {
    width: 45%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    @include mobile {
        gap: 12px;
        width: 100%;
    }
    @include tablet {
        gap: 12px;
        width: 80%;
    }
    p {
        font-size: 20px;
        @include mobile {
            font-size: 16px;
        }
        @include tablet {
            font-size: 18px;
        }
    }
}
.AboutUs_text_wrapper {
    display: flex;
    gap: 90px;
    margin-top: 70px;
    @include mobile {
        flex-direction: column;
        margin-top: 30px;
        gap: 12px;
    }
    @include tablet {
        flex-direction: column;
        margin-top: 30px;
        gap: 12px;
    }
}