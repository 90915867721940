@import '../../global.scss';
.Amenits_headline {
    font-size: 36px;
    margin-top: 130px;
    font-weight: 500;
    @include mobile {
        margin-top: 56px;
        font-size: 22px;
    }
    @include tablet {
        margin-top: 85px;
        font-size: 24px;
    }
}
.Amenits {
    width: 100%;
    margin-top: 70px;
    display: flex;
    flex-direction: column;
    border-top: 1px solid #B0B0B0;
    &_borders {
        width: 55%;
        @include mobile {
            width: 45%;
        }
        @include tablet {
            width: 45%;
        }
        &_first {
            border-right: 1px solid #B0B0B0;
            width: 50%;
            @include mobile {
                width: 55%;
            }
            @include tablet {
                width: 55%;
            }
        }
    }
    &_first_row {
        display: flex;
        border-bottom: 1px solid hsl(0, 0%, 69%);
        width: 100%;
        
        &_first_block {
           padding: 20px 80px 20px 0;
           @include mobile {
            padding: 23px 3px 11px 0;
        }
        @include tablet {
            padding: 24px 0 24px 0;
        }
           p {
            margin-top: 20px;
            font-size: 18px;
              @include mobile {
                  font-size: 12px;
              }
              @include tablet {
                  font-size: 18px;
                  margin-top: 12px;
              }
           }
           h3 {
               color: #5B3992;
               font-size: 23px;
               font-weight: 500;
               @include mobile {
                   font-size: 14px;
               }
               @include tablet {
                font-size: 20px;
            }
           }
        }
        &_second_block {
           padding: 20px 80px 20px 10px; 
           @include mobile {
            padding: 23px 3px 11px 5px;
        }
        @include tablet {
            padding: 24px 0 24px 5px;
        }
           p {
            margin-top: 20px;
            font-size: 18px;
              @include mobile {
                  font-size: 12px;
              }
              @include tablet {
                font-size: 18px;
                margin-top: 12px;
            }
           }
           h3 {
               font-size: 23px;
               font-weight: 500;
               @include mobile {
                   font-size: 14px;
               }
               @include tablet {
                font-size: 20px;
            }
           }
        }

    }
    &_second_row {
        display: flex;
        border-bottom: 1px solid #B0B0B0;

        &_first_block {
            padding: 20px 80px 20px 0;
            @include mobile {
                padding: 23px 3px 11px 0;
            }
            @include tablet {
                padding: 24px 0 24px 0;
            }
            p {
                margin-top: 20px;
                font-size: 18px;
                  @include mobile {
                      font-size: 12px;
                  }
                  @include tablet {
                    font-size: 18px;
                    margin-top: 12px;
                }
               }
               h3 {
                   font-size: 23px;
                   font-weight: 500;
                   @include mobile {
                       font-size: 14px;
                   }
                   @include tablet {
                    font-size: 20px;
                }
               }
         }
         &_second_block {
            padding: 20px 80px 20px 10px;
            @include mobile {
                padding: 23px 3px 11px 5px;
            }
            @include tablet {
                padding: 24px 0 24px 5px;
            }
            p {
                margin-top: 20px;
                font-size: 18px;
                  @include mobile {
                      font-size: 12px;
                  }
                  @include tablet {
                    font-size: 18px;
                    margin-top: 12px;
                }
               }
               h3 {
                   color: #5B3992;
                   font-size: 23px;
                   font-weight: 500;
                   @include mobile {
                       font-size: 14px;
                   }
                   @include tablet {
                       font-size: 20px;
                   }
               }
         }
    }
}