@import '../../global.scss';
.Partners_section {
    margin-top: 130px;
    @include mobile {
        margin-top: 80px;
    }
    @include tablet {
        margin-top: 85px;
    }
    h2 {
        font-size: 36px;
        font-weight: 500;
        @include mobile {
            font-size: 22px;
        }
        @include tablet {
            font-size: 24px;
        }
    }
    .Partners_list_container {
        margin-top: 70px;
        display: flex;
        align-items: center;
        justify-content:space-evenly;
        gap: 110px;
        flex-wrap: wrap;
        width: 95%;
        margin-bottom: 130px;
        @include mobile {
            margin-top: 45px;
            gap: 46px;
            margin-bottom: 80px;
        }
        @include tablet {
            margin-top: 58px;
            gap: 50px;
            margin-bottom: 85px;

        }
        img {
            max-width: 238px;
            max-height: 120px;
            object-fit: contain;
            @include mobile {
                max-height: 75px;
                max-width: 125px;
            }
            @include tablet {
                max-width: 180px;
                max-height: 90px;
            }
        }
    }
}
