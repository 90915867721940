@import '../../global.scss';
.footer {
  width: 100%;
  height: auto;
  background: #28114F;
  &_container{
    width: 90%;
    display: flex;
    margin: 0 auto;
    @include tablet{
      width: 90%;
    }
    @include mobile {
      width: 90%;
      flex-wrap: wrap;
    }
  }
  .block_logo{
    padding-top: 20px;
    img {
      width: 210px;
      height: 80px;
      @include tablet {
        width: 150px;
        height: 70px;
      }
      @include mobile{
        width: 150px;
        height: 70px;
      }
    }
  }
}

.links_container {
  width: 70%;
  height: 40px;
  display: flex;
  justify-content: center;
  padding-top: 30px;
  gap: 55px;
  @include tablet {
    justify-content: start;
    padding-top: 20px;
    padding-left: 0;
    gap: 7%;
    left: 12%;
    z-index: 1;
    position: relative;
  }
  @include mobile {
    padding-top: 25px;
    margin: 0 auto;
    display: grid;
    grid-template-columns:  repeat(2,70px);
    width: 40%;
    height: 58px;
    gap: 20px;
  }
  .links{
    width: max-content;
    white-space: nowrap;
    font-size: 18px;
    color: #FDFDFD;
    @include mobile{
      font-size: 16px;
    }
  }
}
  
.contact{
  padding-top: 30px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  @include tablet{
    position: relative;
    z-index: 0;
    padding-top: 20px;
    right: 4%;
  }
  @include mobile{
    position: static;
    width: 100%;
    align-items: flex-start;
    padding-top: 40px;
    padding-left: 0;
    gap: 10px;
  }
  &_logo {
    display: flex;
    justify-content: end;
    gap: 30px;
    img {
      width: 25px;
      height: 25px;
      @include tablet {
        width: 20px;
        height: 20px;
      }
      @include mobile{
        width: 20px;
        height: 20px;
      }
    }
    @include tablet{
      gap: 12px;
    }
    @include mobile {
      gap: 10px;
    }
  }
  &_container{
    padding-top: 15px;
    display: flex;
    flex-direction: column;
    justify-content: end;
    gap: 10px;
    @include tablet{  
      padding-top: 20px;
      display: flex;
      flex-direction: column;
      justify-content: end;
      align-items: flex-end;
    }
    @include mobile {
      justify-content: end;
    }
  }
  &_text{
    width: auto;
    font-size: 16px;
    font-weight: 500;
    text-align: end;
    white-space: nowrap;
    color: #FDFDFD;
    @include tablet{
      font-size: 16px;
    }
    @include mobile{
      width: 100%;
      font-size: 16px;
      text-align: start;
    }
  }
  &_year{
    display: flex;
    justify-content: end;
    padding-top: 20px;
    padding-bottom: 10px;
    @include tablet {
      justify-content: end;
    }
    @include mobile{
      justify-content: start;
      padding-top: 10px;
      padding-bottom: 10px;
    }
    img{
     @include mobile {
       width: 55px;
       height: 25px;
     }
    }
  }
}


