@import '../../global.scss';
.header {
    width: 100vw;
    background: rgba($color: #5B3992, $alpha: 0.55);
    padding: 10px 0 10px 0;
    z-index: 5;
    border-radius: 0px 0px 20px 20px;
    position: fixed;
    @include mobile {
        padding: 5px 0 5px 0;
    }
    @include tablet {
        padding: 10px 0 10px 0;
    }
    &_container {
        display: flex;
        align-items: center;
    }
    &_links {
        display: flex;
        justify-content: center;
        width: 70%;
        gap: 80px;
        @include mobile {
            gap: 8px;
        }
        @include tablet {
            gap: 20px;
        }
        a {
            color: #FDFDFD;
            font-size: 18px;
            @include mobile {
                font-size: 12px;
            }
            @include tablet {
                font-size: 18px;
            }

        }
    }
    &_contact {
        display: flex;
        justify-self: flex-end;
        gap: 14px;
        .header_contacts {
            @include mobile {
                display: none;
            }
            @include tablet {
                display: none;
            }
            p {
                color: #FDFDFD;
            }
            .header_number {
                font-weight: 500;
                text-align: end;
                
            }
        }
    }
    &_social {
        display: flex;
        align-items: center;
        gap: 13px;
        img {
            @include mobile {
                width: 16px;
                height: 16px;
            }
        }
    }
    .header_logo {
        img {
            @include mobile {
                width: 80px;
                height: 37px;
            }
            @include tablet {
                width: 126px;
                height: 50px;
            }
        }
    }
}

