$purple : #5B3992;
@mixin mobile {
    @media (max-width: 768px){
        @content
    }
};
@mixin tablet {
    @media  (min-width:768px)and (max-width: 1024px){
        @content
    }
}