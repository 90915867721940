@import '../../global.scss';

.chooseUs{
  width: 100%;
  height: max-content;
  background-image: url('../../../public/img/tower.png');
  background-repeat:no-repeat;
  background-position:top center;
  background-size: inherit;
  display: flex;
  flex-direction: column;
  @include mobile{
    background-image: url('../../../public/img/cadonia1.png');
    background-size: cover;
    background-position:top center;
  }
  @include tablet {
    background-size: initial;
  }
  &_block{
    display: flex;
    align-items: flex-start;
    margin: 50px auto;
    @include tablet{
      margin: 40px auto;
    }
    @include mobile {
      margin: 30px auto;
    }
  }
  &_title {
    font-size: 36px;
    color: #FBFBFB;
    @include tablet {
      font-size: 20px;
    }
    @include mobile {
      font-size: 18px;
    }
  }
}
.block_choose{
  margin: auto;
  margin-bottom: 50px;
}
.block {
  display: flex;
  gap: 60px;
  width: 100%;
  @include tablet{
    gap: 40px;
  }
  @include mobile {
    gap: 40px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  &_text{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    @include mobile {
      width: 43%;
    }
  }
  &_title{
    font-size: 24px;
    color: #FBFBFB;
    @include tablet {
      font-size: 18px;
    }
    @include mobile {
      font-size: 15px;
    }
  }
  &_description {
    font-size: 18px;
    font-weight: 400;
    color: #FBFBFB;
    @include tablet {
      font-size: 16px;
    }
    @include mobile {
      font-size: 12px;
    }
  }
}
.span {
  height: 13rem;
  width: 1px;
  background-color: #FDFDFD;
  @include tablet {
    display: none;
  }
  @include mobile {
    display: none;
  }
}