@import '../../global.scss';
.PurpleButton {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px 30px;
    border: none;
    border-radius: 10px;
    background: $purple;
    cursor: pointer;
    @include mobile {
        padding: 10px 18px;
    }
    @include tablet {
        padding: 9.5px 18px;
    }
    &_text {
        text-transform: uppercase;
        color: #FDFDFD;
    }
}