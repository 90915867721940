@import url('https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;700;800;900&display=swap');
@import './global.scss';
  

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
  font-family: "Inter", sans-serif;
}
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.container {
  margin: 0 auto;
  width: 85%;
  @include mobile {
    width: 90%;
  }
  @include tablet {
    width: 90%;
  }
}
a {
  text-decoration: none ;
}

