@import './global.scss';
.MAIN_IMAGE_WRAPPER {
    background-image: url('../public/img/tower.png');
    background-size:100% 101vh;
    width: 100%;
    height: 101vh;
    z-index: -1;
    
    @include mobile {
        height: 48vh;
        background-size: 110% 100%;
    }
    @include tablet {
        height: 110vh;
        background-size: 110% 100%;
    }

}
